import React, { useState } from 'react'
import { Box, Button, InputAdornment, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';

type SearchProps = {
    onClose: () => void
    onSearch: (query: string) => void
}

const Search = ({ onClose, onSearch }: SearchProps) => {
    const [query, setQuery] = useState('');

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newQuery = event.target.value;
        setQuery(newQuery);
        onSearch(newQuery);
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            (event.target as HTMLInputElement).blur();
        }
    };

    const handleCancel = () => {
        setQuery('');
        onSearch('');
        onClose();
    };

    return (
        <Box sx={{ display: 'flex', m: 2 }}>
            <TextField
                fullWidth
                placeholder="Zoeken"
                variant="outlined"
                size="small"
                value={query}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                sx={{
                    backgroundColor: '#293038',
                    borderRadius: 1,
                    '& .MuiOutlinedInput-root': {
                        color: 'white',
                        '& fieldset': {
                            borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                            borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: 'transparent',
                        },
                    },
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon sx={{ color: '#9dabb8', fontSize: '16pt' }} />
                        </InputAdornment>
                    ),
                }}
            />
            <Button
                size="large"
                sx={(theme) => ({
                    color: theme.palette.text.primary,
                })}
                onClick={handleCancel}
            >
                Annuleren
            </Button>
        </Box>
    )
}

export default Search;