import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import StarIcon from '@mui/icons-material/StarBorderRounded';
import { useState } from 'react';
import Search from '../search/Search';
import { useLocation } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

type TopBarProps = {
    onSearch: (query: string) => void;
    onFavoriteFilter: (showFavorites: boolean) => void;
}

const TopBar = ({ onSearch, onFavoriteFilter }: TopBarProps) => {
    const location = useLocation();
    const [showSearchInput, setShowSearchInput] = useState(false);
    const [showFavorites, setShowFavorites] = useState(false);

    const handleFavoriteClick = () => {
        setShowFavorites(!showFavorites);
        onFavoriteFilter(!showFavorites);
    };

    return (
        <Box sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            height: '60px',
            justifyContent: 'space-between',
            backgroundColor: theme.palette.background.default,
        })}>
            {showSearchInput ? (
                <Search onClose={() => setShowSearchInput(false)} onSearch={onSearch} />
            ) : (
                <>
                    <IconButton
                        sx={{ color: 'white', width: '60px', height: '60px', display: location.pathname.includes("/home") ? 'flex' : 'none' }}
                        onClick={handleFavoriteClick}
                    >
                        {!showFavorites ? (
                            <StarIcon />
                        ) : (
                            <Typography
                                sx={{
                                    fontSize: '20px',
                                    animation: showFavorites ? 'twistBounce 1s' : 'none',
                                    '@keyframes twistBounce': {
                                        '0%': { transform: 'perspective(400px) scale(0) rotateY(0)' },
                                        '50%': { transform: 'perspective(400px) scale(1.5) rotateY(360deg)' },
                                        '100%': { transform: 'perspective(400px) scale(1) rotateY(360deg)' },
                                    }
                                }}
                            >
                                ⭐️
                            </Typography>
                        )}
                    </IconButton>
                    <Typography variant="h6" component="h2" sx={{ textAlign: 'center', flex: 1, color: 'white' }}>
                        Meets
                    </Typography>
                    <IconButton
                        sx={{ color: 'white', padding: 2, display: location.pathname.includes("/home") ? 'flex' : 'none' }}
                        onClick={() => setShowSearchInput(true)}
                    >
                        <SearchIcon />
                    </IconButton>
                </>
            )}
        </Box>
    );
}

export default TopBar;