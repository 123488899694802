import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useStytchUser, useStytch } from "@stytch/react";
import FullScreenLoader from "../components/loader/FullScreenLoader";
import { Button, TextField, Box, Typography, Paper } from "@mui/material";
import UserService from "../services/userService";

const ProtectedRoutes: React.FC = () => {
    const { user } = useStytchUser();
    const stytch = useStytch();
    const [isWhitelisted, setIsWhitelisted] = useState<boolean | null>(null);
    const [activationCode, setActivationCode] = useState('');
    const userService = UserService();

    useEffect(() => {
        const checkWhitelistStatus = async () => {
            if (user) {
                try {
                    const userData = await userService.getMe();
                    setIsWhitelisted(userData?.isWhitelisted || false);
                } catch (error) {
                    console.error('Error checking whitelist status:', error);
                    setIsWhitelisted(false);
                }
            }
        };

        checkWhitelistStatus();
    }, [user]);

    const handleActivateReferralCode = async () => {
        try {
            await userService.activateReferralCode(activationCode);
            setIsWhitelisted(true);
        } catch (error) {
            console.error('Failed to activate referral code:', error);
        }
    };

    if (!user) {
        return <Navigate to="/login" replace />;
    }

    if (isWhitelisted === null) {
        return <FullScreenLoader />;
    }

    if (isWhitelisted === false) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '100vh',
                    backgroundColor: '#111418',
                }}
            >
                <Box
                    // elevation={3}
                    sx={{
                        padding: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        maxWidth: '400px',
                        width: '100%',
                    }}
                >
                    <Typography variant="h5" gutterBottom>
                        Toegang geweigerd
                    </Typography>
                    <Typography variant="body1" align="center" gutterBottom>
                        Je hebt nog geen toegang. Voer een toegangstoken in om toegang te krijgen.
                    </Typography>
                    <TextField
                        label="Toegangstoken"
                        value={activationCode}
                        onChange={(e) => setActivationCode(e.target.value)}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleActivateReferralCode}
                        fullWidth
                        sx={{ mt: 2 }}
                    >
                        Activeer toegangstoken
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => stytch.session.revoke()}
                        fullWidth
                        sx={{ mt: 2 }}
                    >
                        Uitloggen
                    </Button>
                </Box>
            </Box>
        );
    }

    return <Outlet />;
};

export default ProtectedRoutes;