import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#3B4751',
        },
        secondary: {
            main: '#ffffff',
        },
        background: {
            default: '#111418',
            paper: '#1c2126',
        },
        text: {
            primary: '#ffffff',
            secondary: '#9dabb8',
        },
    },
    typography: {
        fontFamily: 'Barlow, Arial, sans-serif',
        fontSize: 16, // Increase the base font size (default is 14)
        h4: {
            fontSize: '2.125rem', // Slightly larger h4 (default is 2.125rem)
        },
        body1: {
            fontSize: '1.1rem', // Slightly larger body text (default is 1rem)
        },
        body2: {
            fontSize: '0.95rem', // Slightly larger secondary text (default is 0.875rem)
        },
    },
    components: {
        MuiIconButton: {
            styleOverrides: {
                root: {
                    fontSize: '16pt'
                }
            }
        },

        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                    textTransform: 'none',
                    fontWeight: 700,
                    fontSize: '1rem', // Slightly larger button text
                },
            },
            variants: [
                {
                    props: { size: 'small' },
                    style: {
                        padding: '4px 8px',
                    },
                },
                {
                    props: { size: 'medium' },
                    style: {
                        padding: '6px 16px',
                    },
                },
                {
                    props: { size: 'large' },
                    style: {
                        padding: '8px 22px',
                    },
                },
            ],
        },
    },
});

export default theme;
