import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import FilterBar from '../components/filter-bar/FilterBar';
import EventList from '../components/event-list/EventList';
import TopBar from '../components/top-bar/TopBar';
import NavigationBar from '../components/navigation-bar/NavigationBar';
import EventService from '../services/eventService';
import { Event } from '../types/Event';
import UserService from '../services/userService';
import { User } from '../types/User';
import SortMenu from '../components/sort-menu/SortMenu';

function degreesToRadians(degrees: number) {
  return degrees * Math.PI / 180;
}

function calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number) {
  const earthRadiusKm = 6371;

  const dLat = degreesToRadians(lat2 - lat1);
  const dLon = degreesToRadians(lon2 - lon1);

  lat1 = degreesToRadians(lat1);
  lat2 = degreesToRadians(lat2);

  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return earthRadiusKm * c;
}

const getNextOccurrence = (date: string, recurringDay: number): Date => {
  const eventDate = new Date(date);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  while (eventDate < today || eventDate.getDay() !== recurringDay) {
    eventDate.setDate(eventDate.getDate() + 1);
  }

  return eventDate;
};

const Home: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const eventService = EventService();
  const userService = UserService();
  const [isLoading, setIsLoading] = useState(true);
  const [events, setEvents] = useState<Event[]>([]);
  const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);
  const [filteredEvents, setFilteredEvents] = useState<Event[]>([]);
  const [highlightedMeetId, setHighlightedMeetId] = useState<string | null>(null);
  const { meetId } = useParams<{ meetId: string }>();
  const navigate = useNavigate();
  const [showFavorites, setShowFavorites] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [sortByDistance, setSortByDistance] = useState(false);
  const [userLocation, setUserLocation] = useState<{ lat: number, lng: number } | null>(null);
  const [sortOption, setSortOption] = useState<{ value: string; inverted: boolean }>({ value: 'date', inverted: false });

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await userService.getMe();
        setUser(user);
        setUserId(user?.id || null);
      } catch (error) {
        console.error('Failed to fetch user:', error);
      }
    };
    fetchUser();
    if (!events.length) {
      const fetchEvents = async () => {
        try {
          setIsLoading(true);
          const fetchedEvents = await eventService.getEvents();
          setEvents(fetchedEvents);
          setFilteredEvents(fetchedEvents);
        } catch (error) {
          console.error('Failed to fetch events:', error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchEvents();
    }

    if (meetId) {
      setHighlightedMeetId(meetId);
    } else {
      setHighlightedMeetId(null);
    }
  }, [meetId]);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
      console.log("Geolocation is not available in your browser.");
    }
  }, []);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    filterEvents(query, selectedKeywords, sortOption);
  };

  const handleFilterChange = (newSelectedKeywords: string[]) => {
    setSelectedKeywords(newSelectedKeywords);
    filterEvents(searchQuery, newSelectedKeywords, sortOption);
  };

  const handleFavoriteFilter = (showFavorites: boolean) => {
    setShowFavorites(showFavorites);
    // filterEvents(searchQuery, selectedKeywords);
  };

  const filterEvents = (query: string, keywords: string[], sortOpt = sortOption) => {
    let filtered = events.filter(event =>
      (query === '' || event.title.toLowerCase().includes(query.toLowerCase()) ||
        event.location.toLowerCase().includes(query.toLowerCase())) &&
      (keywords.length === 0 || keywords.every(kw => event.keywords.includes(kw)))
    );

    filtered.sort((a, b) => {
      const dateA = a.recurringDay !== null ? getNextOccurrence(a.date, a.recurringDay!) : new Date(a.date);
      const dateB = b.recurringDay !== null ? getNextOccurrence(b.date, b.recurringDay!) : new Date(b.date);

      switch (sortOpt.value) {
        case 'date':
          return sortOpt.inverted
            ? dateB.getTime() - dateA.getTime()
            : dateA.getTime() - dateB.getTime();
        case 'distance':
          if (userLocation && a.latitude && a.longitude && b.latitude && b.longitude) {
            const distanceA = calculateDistance(userLocation.lat, userLocation.lng, a.latitude, a.longitude);
            const distanceB = calculateDistance(userLocation.lat, userLocation.lng, b.latitude, b.longitude);
            return sortOpt.inverted ? distanceB - distanceA : distanceA - distanceB;
          }
          return 0;
        case 'title':
          return sortOpt.inverted
            ? b.title.localeCompare(a.title)
            : a.title.localeCompare(b.title);
        default:
          return 0;
      }
    });

    setFilteredEvents(filtered);
  };

  const handleScreenClick = () => {
    if (highlightedMeetId) {
      setHighlightedMeetId(null);
      navigate('/home');
    }
  };

  const handleSortChange = (option: { value: string; inverted: boolean }) => {
    setSortOption(option);
    filterEvents(searchQuery, selectedKeywords, option);
  };

  return (
    <Box sx={(theme) => ({
      '@media (min-width: 768px)': {
        display: 'flex',
        flexDirection: 'row-reverse',
        width: '100%'
      }
    })}>
      <>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
            padding: 0,
            '@media (min-width: 768px)': {
              paddingLeft: '60px',
            }
          })}
        >
          <TopBar onSearch={handleSearch} onFavoriteFilter={handleFavoriteFilter} />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FilterBar events={filteredEvents} onFilterChange={handleFilterChange} />
            <SortMenu onSortChange={handleSortChange} />
          </Box>
          <EventList
            user={user}
            userId={userId}
            events={filteredEvents}
            searchQuery={searchQuery}
            loading={isLoading}
            selectedKeywords={selectedKeywords}
            highlightedMeetId={highlightedMeetId}
            showFavorites={showFavorites}
            onScreenClick={handleScreenClick}
            onHighlight={(id: string) => navigate(`/home/${id}`)}
            onEdit={(id: string) => navigate(`/edit/${id}`, { state: { from: '/' } })}
          />
        </Box>
      </>
      <NavigationBar />
    </Box>
  );
};

export default Home;