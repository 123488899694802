import React, { useState, useEffect, useCallback } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useNavigate } from 'react-router-dom';
import EventService from '../../services/eventService';
import UserService from '../../services/userService';
import { EventDTO, Event } from '../../types/Event';
import theme from '../../theme';
import { Chip, IconButton, Typography, FormControlLabel, Checkbox, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';
import FullScreenLoader from '../loader/FullScreenLoader';

type EventFormProps = {
    event?: Event | null;
    loading: boolean
}

const EventForm: React.FC<EventFormProps> = ({ event, loading }) => {
    const libraries: ("places")[] = ["places"];
    const [title, setTitle] = useState('');
    const [location, setLocation] = useState('');
    const [date, setDate] = useState<Dayjs | null>(dayjs());
    const [keywords, setKeywords] = useState<string[]>(['']);
    const [error, setError] = useState('');
    const [locationError, setLocationError] = useState('');
    const [hostId, setHostId] = useState('');
    const [isAutocompleteUsed, setIsAutocompleteUsed] = useState(false);
    const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
    const [latitude, setLatitude] = useState<number | null>(null);
    const [longitude, setLongitude] = useState<number | null>(null);
    const [isRecurring, setIsRecurring] = useState(false);
    const [recurringDay, setRecurringDay] = useState<number | null>(null);

    const navigate = useNavigate();
    const eventService = EventService();
    const userService = UserService();

    useEffect(() => {
        if (event) {
            setTitle(event.title);
            setLocation(event.location);
            setDate(dayjs(event.date));
            setKeywords(event.keywords || []);
            setIsAutocompleteUsed(true);
        }
    }, [event]);

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
        libraries: libraries
    });

    useEffect(() => {
        if (loadError) {
            console.error('Error loading Google Maps API:', loadError);
        }
    }, [loadError]);

    useEffect(() => {
        const fetchUser = async () => {
            const user = await userService.getMe();
            if (user) {
                setHostId(user.id);
            }
        };
        fetchUser();
    }, []);

    const onLoad = useCallback((autocomplete: google.maps.places.Autocomplete) => {
        setAutocomplete(autocomplete);
    }, []);

    const handleKeywordChange = (index: number, value: string) => {
        const newKeywords = [...keywords];
        newKeywords[index] = value;
        setKeywords(newKeywords);
    };

    const addKeyword = () => {
        setKeywords([...keywords, '']);
    };

    const removeKeyword = (index: number) => {
        const newKeywords = keywords.filter((_, i) => i !== index);
        setKeywords(newKeywords);
    };

    const onPlaceChanged = () => {
        setLocationError('');
        if (autocomplete !== null) {
            const place = autocomplete.getPlace();
            if (place.geometry && place.geometry.location) {
                const lat = place.geometry.location.lat();
                const lng = place.geometry.location.lng();
                setLocation(place.formatted_address || '');
                setLatitude(lat);
                setLongitude(lng);
                setIsAutocompleteUsed(true);
            } else {
                setLocation(place.name || '');
                setLatitude(null);
                setLongitude(null);
                setIsAutocompleteUsed(false);
            }
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!title || !location || !date || !hostId) {
            setError('Vul alle verplichte velden in');
            return;
        }
        if (!isAutocompleteUsed) {
            setError('Gebruik een geldig adres voor de locatie.');
            return;
        }

        const eventData: EventDTO = {
            title,
            location,
            date: date.toISOString(),
            host: { id: hostId },
            keywords: keywords.filter(keyword => keyword.trim() !== ''),
            latitude,
            longitude,
            recurringDay: recurringDay,
        };

        try {
            if (event) {
                await eventService.updateEvent(event.id, eventData);
            } else {
                await eventService.createEvent(eventData);
            }
            navigate('/home');
        } catch (error) {
            console.error('Failed to save event:', error);
            setError('Failed to save event. Please try again.');
        }
    };

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: 300,
                margin: '0 auto',
                padding: 4,
                backgroundColor: theme.palette.background.default,
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                color: theme.palette.text.primary,
            })}
        >
            {loading && <FullScreenLoader />}
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {locationError && <p style={{ color: 'red' }}>{locationError}</p>}
            <TextField
                required
                label="Titel"
                variant="outlined"
                fullWidth
                margin="normal"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                InputLabelProps={{ style: { color: theme.palette.text.primary } }}
                InputProps={{
                    style: { color: theme.palette.text.primary, borderColor: theme.palette.text.primary },
                    sx: {
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.palette.primary.main,
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.palette.text.primary,
                        },
                    },
                }}
            />
            {isLoaded && !loadError ? (
                <Box sx={{ width: '100%' }}>
                    <Autocomplete
                        onLoad={onLoad}
                        onPlaceChanged={onPlaceChanged}
                        options={{
                            types: ['address'],
                            componentRestrictions: { country: 'nl' }, // Restrict to Netherlands, change if needed
                            fields: ['address_components', 'formatted_address', 'name', 'geometry'],
                        }}
                    >
                        <TextField
                            label="Locatie"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            InputLabelProps={{ style: { color: theme.palette.text.primary } }}
                            InputProps={{
                                style: { color: theme.palette.text.primary, borderColor: theme.palette.text.primary },
                                sx: {
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: theme.palette.text.primary,
                                    },
                                },
                            }}
                        />
                    </Autocomplete>
                </Box>
            ) : (
                <TextField
                    label="Locatie"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    InputLabelProps={{ style: { color: theme.palette.text.primary } }}
                    InputProps={{
                        style: { color: theme.palette.text.primary, borderColor: theme.palette.text.primary },
                        sx: {
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.primary.main,
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.text.primary,
                            },
                        },
                    }}
                />
            )}
            <FormControlLabel
                control={
                    <Checkbox
                        checked={isRecurring}
                        onChange={(e) => setIsRecurring(e.target.checked)}
                    />
                }
                label="Wekelijks event"
                sx={{
                    width: "100%"
                }}
            />

            {isRecurring ? (
                <TextField
                    select
                    label="Elke"
                    value={recurringDay}
                    onChange={(e) => setRecurringDay(Number(e.target.value))}
                    fullWidth
                    margin="normal"
                >

                    {[0, 1, 2, 3, 4, 5, 6].map((day) => (
                        <MenuItem key={day} value={day}>
                            {['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'][day]}
                        </MenuItem>
                    ))}
                </TextField>
            ) :
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        sx={{ width: '100%', marginTop: '16px', marginBottom: '8px' }}
                        format="DD/MM/YYYY HH:mm"
                        ampm={false}
                        label="Datum"
                        value={date}
                        onChange={(newValue) => setDate(newValue)}
                        closeOnSelect
                    />
                </LocalizationProvider>
            }
            <Typography variant="h6" sx={{ marginTop: '10px', width: '100%', textAlign: 'left' }}>Tags</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2, width: '100%', alignItems: 'center' }}>
                {keywords.map((keyword, index) => (
                    <Chip
                        key={index}
                        label={
                            <TextField
                                value={keyword}
                                onChange={(e) => handleKeywordChange(index, e.target.value)}
                                variant="standard"
                                InputProps={{ disableUnderline: true }}
                                style={{ width: '100%', padding: '0 4px' }}
                            />
                        }
                        onDelete={() => removeKeyword(index)}
                        style={{
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                            margin: '2px',
                            maxWidth: '100px',
                        }}
                    />
                ))}
                <IconButton onClick={addKeyword} color="primary" size="small">
                    <AddIcon fontSize="small" />
                </IconButton>
            </Box>
            <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                sx={{
                    mt: 2,
                    width: '100%',
                }}
            >
                {event ? 'Bijwerken' : 'Aanmaken'}
            </Button>
        </Box >
    );
};

export default EventForm;