import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, TextField, IconButton, Snackbar } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import NavigationBar from '../components/navigation-bar/NavigationBar';
import { useStytch } from '@stytch/react';
import UserService from '../services/userService';
import { User } from '../types/User';
import FullScreenLoader from '../components/loader/FullScreenLoader';

const Profile = () => {
    const stytch = useStytch();
    const [user, setUser] = useState<User | null>(null);
    const [referralCode, setReferralCode] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const userService = UserService();

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const userData = await userService.getMe();
                setUser(userData);
            } catch (error) {
                console.error('Failed to fetch user:', error);
            }
        };
        fetchUser();
    }, []);

    const handleLogout = () => {
        stytch.session.revoke();
    };

    const handleGenerateReferralCode = async () => {
        try {
            const code = await userService.generateReferralCode();
            setReferralCode(code);
        } catch (error) {
            console.error('Failed to generate referral code:', error);
        }
    };

    const handleCopyReferralCode = () => {
        navigator.clipboard.writeText(referralCode);
        setSnackbarOpen(true);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column-reverse', width: '100%' }}>
            {!user && <FullScreenLoader />}
            <NavigationBar />
            <Box
                sx={{
                    display: 'flex',
                    marginTop: '72px',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    backgroundColor: '#111418',
                    color: 'white',
                    padding: 3,
                }}
            >
                <Typography variant="h4" align='center' gutterBottom>
                    Profiel
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        maxWidth: '400px',
                        gap: 2,
                    }}
                >
                    <Typography variant="body1">{`Email: ${user?.email}`}</Typography>

                    {user?.isAdmin && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleGenerateReferralCode}
                            fullWidth
                        >
                            Genereer toegangstoken
                        </Button>
                    )}

                    {referralCode && (
                        <TextField
                            fullWidth
                            variant="outlined"
                            value={referralCode}
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <IconButton sx={{ 'svg': { fontSize: '16pt' } }} onClick={handleCopyReferralCode} edge="end">
                                        <ContentCopyIcon />
                                    </IconButton>
                                ),
                            }}
                        />
                    )}

                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleLogout}
                        fullWidth
                    >
                        Uitloggen
                    </Button>
                </Box>
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
                autoHideDuration={2000}
                message="Toegangstoken gekopieerd naar klembord"
            />
        </Box>
    );
};

export default Profile;